<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formOther/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="titleRules">
        <b-form>
          <b-row>

            <b-col
              cols="6"
            >
              <b-form-group
                label="Título"
                label-for="title"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="título"
                  rules="required"
                >

                  <b-input-group
                    :append="count"
                  >
                    <b-form-input
                      id="name"
                      v-model="title"
                      name="register-number"
                      :state="errors.length > 0 ? false:null"
                      maxlength="47"
                      placeholder="Título"
                      @keyup="countCaracter"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="button"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      title: '',
      count: '0 / 47',
      items: [
        {
          text: 'Título',
          active: false,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.title = this.$store.getters['formOther/title']
    this.link = this.$store.getters['formOther/link']
  },

  methods: {
    next() {
      this.$refs.titleRules.validate().then(success => {
        if (success) {
          this.$store.commit('formOther/UPDATE_TITLE', this.title)
          this.$router.push(`${this.link}-time`)
        }
      })
    },
    cancel() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', true)
      this.$router.push('patient-edit')
    },
    countCaracter() {
      this.count = `${this.title.length} / 47`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
