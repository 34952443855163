<template>
  <div>
    <patient-edit />
    <form-title />
  </div>
</template>

<script>
import patientEdit from '../Patient-edit.vue'
import formTitle from '../../form/other/Form-other-title.vue'

export default ({
  components: {
    patientEdit,
    formTitle,
  },

})
</script>
